/* General CSS */
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
}

:root {
  /* Background Color */
  --bg-white: #fff;

  /* Text Colors */
  --primary-text: #061738;
  --secondary-color: #60cd12;
  --light-color: #e2f6de;
  --dark-color: #03262c;
  --text-white: #fff;
  --text-gray: #dee2e6;
  --anchor-color: #007aff;

  /* Font Family */
  --primary-font: "Barlow Condensed", sans-serif;
  --secondary-font: "Roboto", sans-serif;
}

body,
html {
  color: var(--primary-text);
  font-size: 10px;
  font-weight: 400;
  font-family: var(--primary-font);
  scroll-behavior: smooth;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 9rem;
  line-height: 1.4;
  font-weight: 600;
  color: var(--primary-text);
}

h2 {
  color: var(--primary-text);
  font-size: 4.7rem;
  font-weight: 600;
  display: inline-block;
  text-transform: capitalize;
  line-height: 1.2;
  margin-bottom: 2rem;
}

h3 {
  color: var(--text-white);
  font-size: 3.1rem;
  line-height: 1.2;
  font-weight: 700;
}

h4 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  color: var(--primary-text);
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  color: var(--primary-text);
}

p {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 1rem;
  color: var(--primary-text);
  font-family: var(--secondary-font);
}

embed,
iframe,
img,
object {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:active,
a:focus,
a:hover,
button {
  text-decoration: none;
  outline: 0;
}

li a {
  color: var(--text-white);
}

a:hover,
button:hover {
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: var(--white);
}
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}

.main-btn {
  position: relative;
  color: var(--bg-white);
  background: linear-gradient(to right, #0f7404, #5cb917);
  z-index: 1;
  overflow: hidden;
  border: 0.2rem solid var(--bg-white);
  border-radius: 3.5rem;
  text-transform: uppercase;
  padding: 1rem 3.5rem;
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  letter-spacing: 0.2rem;
}

.main-btn:hover {
  background: var(--bg-white);
  color: var(--secondary-color);
  border: 0.2rem solid var(--secondary-color);
}

section {
  padding: 5rem 0;
}

.section-title {
  font-size: 4rem;
  font-weight: 600;
  color: var(--primary-text);
  text-transform: capitalize;
  margin-bottom: 2rem;
}

.section-subtitle {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--primary-text);
  font-family: var(--secondary-font);
  max-width: 40%;
  margin: auto;
  margin-bottom: 1.5rem;
}

.about .banner_section,
.product .banner_section,
.gallery .banner_section,
.contact .banner_section,
.distributors .banner_section {
  /* background: url("../assets/images/banner-slide/top-banner.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 17rem;
  padding-bottom: 10rem;
  min-height: auto;
}

.contact .contact-form,
.distributors .contact-form {
  background-color: var(--light-color);
  padding: 5rem;
  box-shadow: 0 0.2rem 1.5rem rgb(0 0 0 / 4%);
}

.contact .contact-form .form-control:focus,
.distributors .contact-form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.contact .contact-form .form-control::placeholder,
.distributors .contact-form .form-control::placeholder {
  color: var(--primary-text);
}

.contact .contact-form .form-control,
.distributors .contact-form .form-control {
  padding: 1rem 1.5rem;
  border-radius: 0rem;
  border: 0.1rem solid var(--secondary-color);
  color: var(--primary-text);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--secondary-font);
}

/* 01 navbar css */

/* .header {
  position: absolute;
  width: 100%;
  top: 2rem;
  left: 0;
  z-index: 2;
} */

.header .navbar {
  background: url("../assets/images/header-bg.png");
  /* background: #03262c; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 2rem 5rem;
}

.navbar-brand {
  width: 7.5rem;
  margin-top: -0.1rem;
}

.header .navbar-nav .nav-link {
  color: var(--text-white);
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  margin: 0 0.2rem;
  font-family: var(--primary-font);
}

.header .navbar-nav .nav-link:hover,
.header .navbar-nav .nav-link.active {
  color: var(--secondary-color);
}

.header .navbar-toggler {
  color: var(--text-white);
  font-size: 2rem;
  height: 2.7rem;
  padding: 0;
}

.header .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.header .header-right ul img {
  width: 2rem;
}

.header .header_right span {
  color: var(--secondary-color);
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  margin-right: 1rem;
}

.header .header_right .phone_no {
  color: var(--text-white);
  font-size: 1.6rem;
}

.header .header_right a {
  height: 3.5rem;
  width: 3.5rem;
  text-align: center;
  background-color: #13424a;
  border-radius: 50%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-scrolled {
  position: fixed;
  top: -0.4rem;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1320px;
  margin: auto;
  z-index: 999;
  /* box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%); */
  transition: 0.3s ease-in-out;
}

/* 02 Banner Section */

.banner_section {
  background: url("../assets/images/2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 90vh;
}
.banner-content h1,
.banner-content p {
  color: #dcdada;
}
#video {
  position: absolute;
  width: 90%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: blur(1px);
}

.banner_section .carousel-item {
  padding: 3rem 0;
  height: 90vh;
}

.banner_section .carousel-caption {
  top: 30%;
  bottom: 0;
  left: 3%;
  right: 0;
  text-align: left;
  width: 100%;
}

.banner_section .carousel-caption h3 {
  padding: 0.5rem 1.5rem;
  background-color: var(--dark-color);
  display: inline;
}

.banner_section .carousel-caption p {
  max-width: 40rem;
  margin-bottom: 2rem;
}

/* Features CSS */

.feature_section .features-box {
  background-color: transparent;
  border: 0.15rem solid var(--light-color);
  padding: 8rem 0;
  transition: all 0.3s;
}

.feature_section .features-box .features-icon-border {
  background-color: transparent;
  border-radius: 50%;
  border: 0.2rem solid var(--light-color);
  padding: 1.3rem;
  display: inline-block;
}

.feature_section .features-box .features-icon {
  height: 6.5rem;
  width: 6.5rem;
  background-color: var(--light-color);
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature_section .features-box .features-icon img {
  width: 3.7rem;
}

.feature_section .features-box .features-icon i {
  font-size: 10px;
}
.feature_section .features-box h3 {
  margin-top: 1.5rem;
  color: var(--primary-text);
}

.feature_section .features-box:hover {
  background-color: #03262c;
  border-color: #03262c;
}

.feature_section .features-box:hover h3 {
  color: #fff;
}

.feature_section .features-box:hover .features-icon-border {
  border-color: #36970e;
}

/* About Section */

.landing_about_section {
  background: url("../assets/images/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 9rem 0;
}
.about-content h2 {
  color: #dcdada;
}
.landing_about_section p {
  color: #dcdada;
  margin-bottom: 2rem;
}

/* product card design */

.landing_product_section .product-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: transparent;
  border: 0.2rem solid var(--light-color);
  padding: 2rem 1rem;
  position: relative;
  transition: all 0.3s;
}

.landing_product_section .product-card .product-img {
  margin-bottom: 7rem;
  text-align: center;
}

.landing_product_section .product-card h3 {
  color: var(--primary-text);
  font-size: 2.5rem;
}

.landing_product_section .product-card span {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--secondary-color);
  margin-right: 0.5rem;
  text-decoration: line-through;
  transition: all 0.3s;
}

.landing_product_section .product-detail {
  position: absolute;
  bottom: -22rem;
  right: 0;
  left: 0;
  text-align: center;
  padding: 5rem 1rem;
  background-color: var(--dark-color);
  transition: all 0.5s ease;
}

.landing_product_section .product-card:hover .product-detail {
  bottom: 0;
}

/* 4.5 Testimonial */
.testimonial_section {
  background-color: var(--light-color);
  padding-bottom: 8rem;
}

.testimonial_section .carousel-indicators {
  bottom: -6rem;
}

.testimonial_section .carousel-indicators button {
  background-color: var(--dark-color);
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}

.testimonial_section .card {
  background: url("../assets/images/testimonial/testimonial-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: right;
  padding: 5rem 5rem 5rem 0;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 0.1rem solid transparent;
}

.testimonial_section .profile-box {
  padding: 1.875rem;
  background-color: var(--dark-color);
  border-radius: 0.5rem 0 0 0.5rem;
}

.testimonial_section .profile-box img {
  border-radius: 0.625rem 3.125rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial_section .desc-box {
  padding-left: 2.5rem;
}

/* Gallery CSS */

.clearfix {
  clear: both;
}

.gallery_section img {
  width: 330px;
  height: 220px;
  object-fit: cover;
}

/* Footer CSS */

.footer_wrapper {
  background: url("../assets/images/footer-bg/footer_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-top: 15rem;
  padding-bottom: 0rem;
}

.footer_wrapper h5 {
  color: var(--text-white);
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
}

.footer_wrapper ul li {
  margin-bottom: 0.5rem;
  list-style: none;
}

.footer_wrapper .company_details {
  font-size: 1.4rem;
}

.footer_wrapper .contact-info li a {
  color: var(--text-gray);
  font-size: 1.6rem;
}

.footer_wrapper .link-widget li a,
.footer_wrapper p {
  color: var(--text-gray);
  font-size: 1.6rem;
  padding-left: 1.5rem;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.footer_wrapper .link-widget li a::before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0.3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer_wrapper .link-widget li a:hover {
  margin-left: 0.625rem;
  color: var(--secondary-color);
}

.footer_wrapper .social-network a {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0.5rem;
  line-height: 3.2rem;
  font-size: 1.5rem;
  display: inline-block;
  border: 0.125rem solid var(--text-gray);
  color: var(--text-gray);
  text-align: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer_wrapper .social-network a:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--text-white);
  box-shadow: 0 0.625rem 0.9375rem 0 rgb(0 0 0 / 10%);
  transform: translateY(-0.1875rem);
}

.footer_wrapper .form-control {
  font-size: 2rem;
  color: white;
}

.footer_wrapper .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--secondary-color);
}

.footer_wrapper .copyright-section {
  background-color: var(--secondary-color);
  text-align: center;
  margin-top: 5rem;
}

.footer_wrapper .copyright-section p {
  margin-top: 0;
  padding: 3rem 0;
  line-height: 0;
}

.footer_wrapper .copyright-section a {
  color: var(--primary-text);
  font-weight: 600;
}

/* ===========================
           About Page CSS
           ============================== */

.about .about-details p {
  color: var(--primary-text);
}

/* ===========================
           Contact Page CSS
           ============================== */
.contact .contact-form textarea {
  min-height: 15rem;
}

.contact .content-box .info-box li {
  position: relative;
  padding-left: 7rem;
  padding-bottom: 3.5rem;
  margin-bottom: 2.9rem;
}

.contact .content-box .info-box li i {
  position: absolute;
  left: 0;
  top: 1.5rem;
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: var(--primary-text);
}

.contact .content-box .info-box li p {
  color: var(--primary-text);
}

.contact .content-box .info-box li a {
  color: var(--dark-color);
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 0;
  font-family: var(--secondary-font);
}
.hamburger {
  display: none;
}
/* ===========================
           Product Page CSS
           ============================== */

.product .product-details p {
  margin-bottom: 1.5rem;
}

/* <!--Responsive CSS--> */

/* Media Query 1170px */
@media screen and (max-width: 1170px) {
  /* General CSS */
  .section-subtitle {
    max-width: 60%;
  }

  /* 01 Navbar CSS */
  .header .navbar {
    background-size: cover;
    padding: 2rem;
  }
  .navbar-nav.active {
    left: 0;
  }
  .hamburger {
    display: block;
  }
  .header .navbar-nav {
    margin-top: 2rem;
  }

  .header .navbar-nav .nav-link {
    padding: 0.2rem 0.5rem;
    margin-bottom: 1rem;
  }

  .header .header_right span {
    padding: 0.2rem 0.5rem;
  }

  /* 02 Banner Section */
  .banner_section .carousel-item {
    height: 90vh;
  }
}

/* Media Query 991px */
@media (max-width: 991px) {
  /* 01 Navbar CSS */
  .header .navbar {
    background-size: cover;
    padding: 1rem 2rem;
  }

  .header .navbar-nav {
    margin-top: 2rem;
  }
  .hamburger {
    display: block;
  }
  .header .navbar-nav .nav-link {
    padding: 0.2rem 0.5rem;
    margin-bottom: 1rem;
  }

  .header .header_right span {
    padding: 0.2rem 0.5rem;
  }

  /* General CSS */
  .about .banner_section,
  .product .banner_section,
  .Gallery .banner_section,
  .contact .banner_section {
    padding-top: 12.5rem;
    padding-bottom: 3rem;
  }
}

/* Media Query 767px */
@media (max-width: 767px) {
  /* General CSS */
  body,
  html {
    font-size: 8px;
  }

  .section-subtitle {
    max-width: 80%;
  }
  .hamburger {
    display: block;
  }
  /* 02 Banner Section */
  .banner_section {
    min-height: 70vh;
  }

  .banner_section .carousel-item {
    height: 70vh;
  }
}

/* Media Query 590px */
@media (max-width: 590px) {
  /* General CSS */
  body,
  html {
    font-size: 7px;
  }
  .hamburger {
    display: block;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
}
.logo {
  height: 100px;
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.header-bg {
  background-color: rgba(0, 0, 0, 0.9);
  transition: 0.5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1240px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: -100%;
    text-align: center;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    z-index: -1;
    background: rgba(0, 0, 0, 0.9);
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
  }

  .nav-menu a {
    font-size: 2rem;
  }
}

/* 

One of your dependencies, babel-preset-react-app, is importing the
"@babel/plugin-proposal-private-property-in-object" package without
declaring it in its dependencies. This is currently working because
"@babel/plugin-proposal-private-property-in-object" is already in your
node_modules folder for unrelated reasons, but it may break at any time.

babel-preset-react-app is part of the create-react-app project, which
is not maintianed anymore. It is thus unlikely that this bug will
ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
your devDependencies to work around this error. This will make this message
go away. */
